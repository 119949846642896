

export default {
    methods: {
        getTriggersShutters(props, callback=() => false, callback2=() => false) {
            return {
                'proposition-acte': {
                    name: 'proposition-acte',
                    title: this.$t('acte.trigger.declencher.acte'),
                    cmpPath: 'components/Triggers/ActeProposition',
                    onOk: callback,
                    onCancel: callback2,
                    props
                },
            }
        },

        async setupActProposition(horses_ids, date, acte_type, questions, lieu, actes_ids, callback, callback2) {
            const shutters = this.getTriggersShutters({
                horses_ids      : horses_ids,
                date_acte       : date,
                acte_selected   : acte_type,
                question        : questions,
                acte_statut     : true,
                actes_ids       : actes_ids,
                shutter_name    : 'proposition-acte',
			}, callback, callback2)
			
			await this.shutterPanel().open(shutters['proposition-acte'])
        },  

        async setupActPropositionMouvement(horses_ids, date, type_mvt, date_retour, from, callback, callback2) {
            const shutters = this.getTriggersShutters({
                horses_ids      : horses_ids,
                date_acte       : date,
                type_mvt        : type_mvt,
                date_retour     : date_retour,
                shutter_name    : 'proposition-acte',
                from            : from
            }, callback, callback2)
            
            await this.shutterPanel().open(shutters['proposition-acte'])
        },

        async setupActPropositionPoulain(horse_id, date, mare_id, callback, callback2) {
            const shutters = this.getTriggersShutters({
                horses_ids      : [horse_id],
                date_acte       : date,
                mare_id         : mare_id,
                shutter_name    : 'proposition-acte',
            }, callback, callback2)
            
            await this.shutterPanel().open(shutters['proposition-acte'])
        },

        async setupNewTriggered(horses_ids, date_acte, entity_type, mare_id, next, acte_type, actes_ids) {
            const triggered_cmp = {
                cheval                  : 'components/Triggers/HorseCreationTriggered',
                pension                 : 'components/Triggers/PensionAjoutTriggered',
                pension_reouverture     : 'components/Triggers/PensionAjoutTriggered',
                pension_cloture         : 'components/Triggers/PensionAjoutTriggered',
                mouvement               : 'components/WriteMouvement',
                acte                    : 'components/WriteActe',
                resultat_analyse        : 'components/Triggers/ResultAnalyse',
                suppression_acte        : 'components/Triggers/SuppressionActe',
                add_categorie           : 'components/Triggers/AjoutCategorie',
                add_embryon             : 'components/Triggers/AjoutEmbryon',
                change_categorie        : 'components/Triggers/ChangeCategorie',
                consommation_paillettes : 'components/Triggers/ConsommationPaillettes',
                note                    : 'components/Triggers/WriteNote',
                mouvement_interne       : 'components/WriteMouvement',
                interroger_dps          : 'components/Triggers/AskDPS',
                solde_contract          : 'components/Triggers/SoldeContract',
                ordonnance              : 'components/Triggers/Ordonnance',
                add_semence_lot         : 'components/Triggers/AddSemenceLot'
            }

            const name = entity_type
            const props = {
                horses_ids      : horses_ids,
                date_acte       : date_acte,
                date_preselect  : date_acte,
                mare_id         : mare_id,
                shutter_name    : name,
                next            : next,
                acte_type       : acte_type,
                actes_ids       : actes_ids,
                type_mvt        : entity_type == 'mouvement_interne' ? 'interne' : null
            }
            
            await this.shutterPanel().open({
                name: name,
                title: this.$t('acte.trigger.declencher.'+entity_type),
                cmpPath: triggered_cmp[entity_type],
                props
            })
        },

        // async setupNewActTriggered(acte_id, parent) {
        //     const name = parent + "_acte-" + acte_id
        //     const props = {
        //         actes_ids: [acte_id],
        //         validation: true
        //     }

        //     await this.shutterPanel().open({
        //         name: name,
        //         title: this.$t('acte.trigger.declencher.acte'),
        //         cmpPath: 'components/WriteActe',
        //         props
        //     })
        // }
    }
}
